import { getInstance } from "@/auth/index";
import User from '@/store/Models/User'

import parseParams from './filterHandler';
const request = require('superagent');
import { debounce } from 'vue-debounce'

import MyLeads from './MyLeads'
import AvailableLeads from './AvailableLeads'
import Agents from './Agent'
import Application from './Application'
import Category from './Category'
import Page from './Page'
import Form from './Form'
import Image from './Image'
import UserManagement from './UserManagement'
import Support from './Support'
import SearchResults from './SearchResults'
import Admin from './Admin'
import PopupNotices from './PopupNotices'
import PopupLog from './PopupLog'
import Leaderboards from './Leaderboards'
import AgentManagement from './AgentManagement'
import AdminReports from './AdminReports'
import Document from './Document'
import Carrier from './Carrier'
import Stats from './Stats'
import PolicyPending from './PolicyPending'
import PolicyPlaced from './PolicyPlaced'
import LeadOrders from './LeadOrders'
import Product from './Product'
import ProductCode from './ProductCode'
import Commission from './Commission'
import FIF from './FIF'
import Division from './Division'
import DivisionCarrier from './DivisionCarrier'
import DivisionCarrierProduct from './DivisionCarrierProduct'
import AdInsertionOrders from './AdInsertionOrders'
import AgentAccount from './AgentAccount'
import PerformDash from './PerformDash'
import AppMenus from './AppMenus'

import { ga_page_view } from '@/GoogleAnalytics'
import Summit from "./Summit";
import Lightspeed from "./Lightspeed"

export default {
    current_request: {},
    request_q: {},
    ...MyLeads,
    ...AvailableLeads,
    ...Agents,
    ...Application,
    ...Category,
    ...Page,
    ...Image,
    ...UserManagement,
    ...Support,
    ...SearchResults,
    ...Admin,
    ...PopupNotices,
    ...PopupLog,
    ...Leaderboards,
    ...AgentManagement,
    ...AdminReports,
    ...Document,
    ...Carrier,
    ...Stats,
    ...PolicyPending,
    ...PolicyPlaced,
    ...LeadOrders,
    ...Product,
    ...ProductCode,
    ...Commission,
    ...FIF,
    ...Division,
    ...DivisionCarrier,
    ...DivisionCarrierProduct,
    ...AdInsertionOrders,
    ...Summit,
    ...Form,
    ...Lightspeed,
    ...AgentAccount,
	...PerformDash,
	...AppMenus,

    config() {
        return new Promise((resolve, reject) => {
            getInstance().getTokenSilently().then(function(token) {
                resolve(token);
            });
        })
    },

    pushQ(q, request, prom) {
        if (typeof q != 'undefined') {
            this.request_q[q] = request;
        }
    },

    pullQ(q) {
        if (typeof q != 'undefined') {
            this.request_q[q] = null;
        }
    },

    cancelQRequest(q) {
        if (typeof this.request_q[q] != 'undefined' && this.request_q[q] != null && typeof this.request_q[q].abort != 'undefined') {
            this.request_q[q].abort();
        }
    },

    getRequest(url, params, q, raw) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                g.current_request = request
                    .get(url)
                    .query(params)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Accept', 'application/json')
                    .set('Cache-Control', "no-cache")
					.responseType('blob') // Initially set to 'blob' to handle binary data
                g.pushQ(q, g.current_request, this)

                g.current_request.then(function(response) {
                        g.pullQ(q)
                        g.ga("call", url, "get");
						const contentType = response.headers['content-type']
						if (contentType && contentType.includes('application/json')) {
							// If the response is JSON, convert the blob to JSON
							const reader = new FileReader()
							reader.onload = function () {
								const jsonResponse = JSON.parse(reader.result)
								resolve(jsonResponse)
							}
							reader.readAsText(response.body)
						} else {
							// For other content types, resolve with the blob data
							resolve(response.body)
						}
                    })
                    .catch(function(err) {
                        g.ga("error", url, "get");
                        if (err.status == 404) {
                            var msg = {
                                msg: err.response.body.message,
                                errors: "Page not found"
                            }
                            reject(msg);
                            return;
                        }
                        if (err.error) {
                            var error_message = "";
                            Object.keys(err.response.body.errors).forEach(function(k) {
                                error_message = error_message + err.response.body.errors[k] + " ";
                            })
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message,
                                error: true
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                        reject(err);
                    });;
            })
        })
    },

    postRequest(url, data) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                request
                    .post(url, data)
                    //.set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .set('Cache-Control', "no-cache")
                    .type('json')
                    //.type('json')
                    //.on('error', function(err, n) {
                    //    console.log("EERRRRRRORR")
                    //    console.log(err, n);
                    //    reject(err);
                    //})
                    .then(function(result) {
                        g.ga("call", url, "post");
                        resolve(result.body);
                    })
                    .catch(function(err) {
                        g.ga("error", url, "post");
                        if (err.response) {
                            var error_message = "";
                            if (typeof err.response.body == "object" && err.response.body != null) {
                                if (typeof err.response.body.errors == "object") {
                                    Object.keys(err.response.body.errors).forEach(function(k) {
                                        error_message = error_message + err.response.body.errors[k] + " ";
                                    })
                                } else if (typeof err.response.body.exception != "undefined") {
                                    errors: err.response.body.exception
                                }
                                var msg = {
                                    msg: err.response.body.message,
                                    errors: error_message,
                                    error: true
                                }
                            } else {
                                var msg = {
                                    msg: "Server Error",
                                    errors: "No response returned",
                                    error: true
                                }
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                    });
            })
        })
    },

    putRequest(url, data) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                request
                    .put(url, data)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Cache-Control', "no-cache")
                    .type('json')
                    .then(function(result) {
                        g.ga("call", url, "put");
                        resolve(result.body);
                    })
                    .catch(function(err) {
                        g.ga("error", url, "put");
                        if (err.response) {
                            var error_message = "";
                            if (typeof err.response.body.errors == "object") {
                                Object.keys(err.response.body.errors).forEach(function(k) {
                                    error_message = error_message + err.response.body.errors[k] + " ";
                                })
                            } else if (typeof err.response.body.exception != "undefined") {
                                errors: err.response.body.exception
                            }
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message,
                                error: true
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                    });
            })
        })
    },

    deleteRequest(url) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                request
                    .delete(url)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Cache-Control', "no-cache")
                    .type('json')
                    .then(function(result) {
                        g.ga("call", url, "delete");
                        resolve(result.body);
                    })
                    .catch(function(err) {
                        g.ga("error", url, "delete");
                        if (err.response) {
                            var error_message = "";
                            if (typeof err.response.body.errors == "object") {
                                Object.keys(err.response.body.errors).forEach(function(k) {
                                    error_message = error_message + err.response.body.errors[k] + " ";
                                })
                            } else if (typeof err.response.body.exception != "undefined") {
                                errors: err.response.body.exception
                            }
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message,
                                error: true
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                    });
            })
        })
    },

    ga: function(type, url, method) {
        ga_page_view(method, {
            'event_category': 'API_' + type,
            'event_label': url,
        })
    },

}