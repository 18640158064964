<template>
    <v-container fluid class="grey lighten-5 my-0 px-0 py-0">
        <v-row :class="rowClass">
            <v-col cols="12" md="6">
                <h2>
					Documents Library
                    <v-btn icon @click="updateDocumentList">
                        <v-icon>fas fa-redo-alt</v-icon>
                    </v-btn>
                </h2>
				<div class="d-flex flex-wrap" style="gap: 1rem">
					<v-btn outlined color="primary" @click="doShowUploadNewDocumentDialog = !doShowUploadNewDocumentDialog">Add a Document</v-btn>
					<v-btn v-if="hasMarketingResourcePermission" outlined color="primary" @click="doShowUploadNewMarketingResource = !doShowUploadNewMarketingResource">Add a Marketing Resource</v-btn>
				</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3">
                <q-division-select label="Division*" v-model="Division" :with-all="true"></q-division-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-select v-model="CategoryName" label="Category" item-text="category" item-value="category" :items="available_categories"></v-select>
            </v-col>
        </v-row>
        <v-row :class="rowClass">
            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="search" label="Search" clearable></v-text-field>
            </v-col>
        </v-row>
        <v-row :class="rowClass">
            <v-col cols="12">
                <v-data-table :mobile-breakpoint="2" :search="search" :headers="the_headers" :items="filteredDocumentList"
                    item-key="ID" class="elevation-0 leaderboard-datatable" :loading="loading"
                    @click:row="previewDocument" :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
                    :items-per-page.sync="rows" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template v-slot:item.ID="{ item }">
                        <span class="nowrap">
                            <v-btn color="q_blue_1" small fab icon @click.stop="downloadDoc(item)">
                                <v-icon>fas fa-download</v-icon>
                            </v-btn>
                            <v-btn color="q_blue_1" small fab icon @click.stop="previewDocument(item)">
                                <v-icon>fas fa-eye</v-icon>
                            </v-btn>
                            <v-btn color="q_resources_1" small fab icon @click.stop="editDoc(item)">
                                <v-icon>fas fa-edit</v-icon>
                            </v-btn>
                            <v-btn small fab icon @click.stop v-clipboard:copy="item.ViewLink" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError">
                                <v-icon>fas fa-copy</v-icon>
                            </v-btn>
                        </span>
                    </template>
                    <template v-slot:item.DocTitle="{ item }">
                        <span>
                            <strong>{{ item.DocTitle }}</strong>
                            <v-btn v-if="isPopup" class="ml-5" small @click.stop="useFile(item.ViewLink, 'link')">Link
                                <v-icon right>fas fa-arrow-circle-right</v-icon>
                            </v-btn>
                            <v-btn v-if="isPopup && !justLink" class="ml-5" small
                                @click.stop="useFile(item.ViewLink, 'button-2')">Button
                                <v-icon right>fas fa-arrow-circle-right</v-icon>
                            </v-btn>
                        </span>
                    </template>
                    <template v-slot:item.LastChangeDate="{ item }">
                        {{ formatDate(item.LastChangeDate) }}
                    </template>
                    <template v-slot:item.DocType="{ item }">
                        <span>
                            <v-icon color="q_new_b_1" left>{{ getDocIcon(item.DocType) }}</v-icon>
                            {{ item.DocType }}
                        </span>
                    </template>
                    <template v-slot:item.Delete="{ item }">
                        <span v-if="item.DocType != 'delete'">
                            <v-btn color="red" small fab icon @click.stop="deleteDoc(item)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </span>
                        <span v-else>
                            <v-progress-circular indeterminate></v-progress-circular>
                        </span>
                    </template>
                    <template #item.CategoryName="{ item }">
                        {{ categoryTypeToText(item.CategoryName) }}
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12">
                <p><b>HQ Document Security Details</b></p>
                <p>All documents uploaded are "secure" by default. What exactly does this mean? You can share the url
                    with anyone (even someone outside of HQ!), and that url will be available and publicly accessible
                    for 1 hour. After an hour as passed, that url will require a valid HQ sign in to view the document.
                    This way documents in HQ can be shared but will not be crawled by search engines and will not be
                    permanently accessible. If you would like a document to be available publicly, longer than an hour,
                    you can "edit" the document and uncheck the secure checkbox. After doing so the document url will be
                    available publicly and could possibly be crawled by search engines!</p>
            </v-col>
        </v-row>

        <QDocPreview v-model="showPreview" :doc="currentDoc" />

        <v-dialog v-model="showEditDoc" v-bind="dialogProps">
            <v-card>
                <v-card-title>Edit Document Properties</v-card-title>
                <v-card-text v-if="showEditDoc">
                    <EditDocument
						:doc="currentDoc"
						do-not-require-file
						:loading="saving"
						@submit="(args) => saveChanges(args, () => { showEditDoc = false })"
					>
                        <template #left-to-save>
                            <v-btn text @click="showEditDoc = false">Close</v-btn>
                        </template>
                    </EditDocument>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="doShowUploadNewMarketingResource" v-bind="dialogProps">
            <v-card>
                <v-card-title>Upload New Marketing Resource</v-card-title>
                <v-card-text>
                    <EditDocument
                        v-if="doShowUploadNewMarketingResource"
                        :doc="currentDoc"
						:loading="saving"
                        @submit="(args) => saveChanges(args, () => { doShowUploadNewMarketingResource = false })"
                    >
                        <template #left-to-save>
                            <v-btn text @click="doShowUploadNewMarketingResource = false">Close</v-btn>
                        </template>
                    </EditDocument>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="doShowUploadNewDocumentDialog" v-bind="dialogProps">
            <v-card>
                <v-card-title>Upload New Document</v-card-title>
                <v-card-text>
                    <EditDocument
                        v-if="doShowUploadNewDocumentDialog"
                        :doc="currentDoc"
						:loading="saving"
                        @submit="(args) => saveChanges(args, () => { doShowUploadNewDocumentDialog = false })"
                    >
                        <template #left-to-save>
                            <v-btn text @click="doShowUploadNewDocumentDialog = false">Close</v-btn>
                        </template>
                    </EditDocument>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
import Category from '@/store/Models/Category'
import EditDocument from '../../components/document_library/EditDocument.vue'
import QDocPreview from '@/CMS/editor/components/QDocPreview.vue'
import QDocPreviewMixin from '@/CMS/editor/components/QDocPreviewMixin'

const searchDefaults = {
	EditorDefinedWeight: 5,
	Keywords: null,
	SearchableEndDate: null,
}

export default {
    props: ['isPopup', 'justLink'],
	mixins: [QDocPreviewMixin],
    data() {
        return {
            loading: false,
            saving: false,
            doShowUploadNewDocumentDialog: false,
            doShowUploadNewMarketingResource: false,
            documentList: [],
            showPreview: false,
            search: null,
            rows: 25,
            showEditDoc: false,
            currentDoc: {},
            Division: 'All',
            CategoryName: null,
            sortBy: 'LastChangeDate',
            sortDesc: true,
        }
    },
    mounted: function () {
        this.CategoryName = this.available_categories[0].category
        this.updateDocumentList();
    },
    computed: {
        the_headers: function () {
            var headers = [
                {
                    text: '',
                    value: 'ID',
                    width: "200",
					sortable: false,
                },
                {
                    text: 'Title',
                    value: 'DocTitle',
                },
                {
                    text: 'Updated Date',
                    value: 'LastChangeDate',
                },
                {
                    text: 'File Type',
                    value: 'DocType',
                },
			]
            if (this.hasMarketingResourcePermission) {
                headers.push( {
                    text: 'Document Type',
                    value: 'CategoryName',
                })
            }
            if (this.hasPermission('manage:Agent')) {
                headers.push({
                    text: 'Delete',
                    value: 'Delete',
                    width: "100"
                })
            }
            return headers;
        },
		hasMarketingResourcePermission () {
            return this.hasPermission('manage:MarketingResourceLibrary')
        },
        available_categories () {
            var cats = Category.query().where('zendesk_category_id', 0).orderBy('category', 'asc').get();
            cats.unshift({ category: "-All-" });
            return cats;
        },
        filteredDocumentList () {
            // This must be also used on backend
            if (this.hasMarketingResourcePermission) { return this.documentList }
            return this.documentList.filter((v) => v.CategoryName !== 'Marketing Resource Library')
        },
		dialogProps () {
			return {
				fullscreen: this.$vuetify.breakpoint.xs,
				width: '90%',
				maxWidth: '800px',
			}
		},
		rowClass () {
			return this.$vuetify.breakpoint.xs ? 'mx-2' : 'mx-5'
		},
    },
    methods: {
        updateDocumentList() {
            var g = this
            this.loading = true;
            QuilityAPI.getDivisionDocumentList(this.Division, this.CategoryName).then(function (json) {
                g.$set(g, 'documentList', json.data)
                g.loading = false;
            }).catch(function (err) {
                g.showError(err.msg)
                console.log(err)
                g.loading = false;
            })
        },
        previewDocument (doc) {
			this.currentDoc = doc
			this.showPreview = true
        },
        editDoc: function (doc) {
            this.$set(this, 'currentDoc', doc);
            this.showEditDoc = true;
        },
        deleteDoc: function (doc) {
            var g = this
            doc.DocType = "delete"
            QuilityAPI.deleteAgentDocument(doc.ID)
                .then(
                    function (json) {
                        if (json.success === true) {
                            var ix = g.indexByKey(doc.ID, g.documentList, "ID");
                            g.documentList.splice(ix, 1);
                        } else {
                            g.showError("Could not delete this file.")
                        }
                    })
                .catch(
                    function (err) {
                        g.showError(err)
                    })
        },
        saveChanges ({ meta, file }, cb) {
			this.saving = true
            QuilityAPI.uploadDivisionDocument(meta, file)
                .then((json) => {
					if (json.success === true) {
						const ix = this.indexByKey(this.currentDoc.ID, this.documentList, "ID");
						if (ix === -1) {
                        	this.documentList.unshift(json.file);
						} else {
							this.$set(this.documentList, ix, json.file);
						}
						cb()
					} else {
						this.showError("Could not save changes.")
					}
					this.saving = false
				})
                .catch((err) => {
					this.showError(err)
					this.saving = false
				})
        },
        useFile: function (file, style, title) {
            this.$emit('usefile', { 'url': file, 'style': style, 'title': title });
        },
        handleCopySuccess () {
            this.showInfo("Document URL copied to clipboard!")
        },
        handleCopyError () {
            this.showError("Opps, something went wrong. Select the desired text and use CTRL+C to copy.")
        },
        categoryTypeToText (categoryType) {
            return categoryType === 'Marketing Resource Library' ? 'Marketing Resource' : 'Document'
        },
    },
    watch: {
        Division () {
            this.updateDocumentList()
        },
        CategoryName () {
            this.updateDocumentList()
        },
        doShowUploadNewDocumentDialog () {
            this.currentDoc = {
                DocTitle: '',
                DocDesc: '',
				Division: this.Division,
				CategoryName: this.CategoryName,
                SecureDocumentInd: false,
                SearchableInd: false,
				...searchDefaults,
            }
        },
        doShowUploadNewMarketingResource () {
            this.currentDoc = {
                DocTitle: '',
                DocDesc: '',
                Division: 'All',
                CategoryName: 'Marketing Resource Library',
				DocumentTags: [],
				SubCategory: '',
				Audience: '',
                SecureDocumentInd: false,
                SearchableInd: false,
				...searchDefaults,
            }
        },
		showPreview (val) {
            if (!val) { this.currentDoc = {} }
        },
    },
    components: {
        QFileUpload,
		QDocPreview,
        QDivisionSelect,
		EditDocument,
    }
}
</script>
