<template>
    <div>
        <v-autocomplete
			v-model="agent"
			:items="items"
			:search-input.sync="search_agents"
			:rules="rules"
			:loading="isLoading"
			:item-text="getItemTextLabel"
			:item-value="itemValue"
			:label="label"
			:placeholder="placeholder"
			prepend-icon="fas fa-user"
			validate-on-blur
			cache-items
			auto-select-first
			clearable
			return-object
			:no-data-text="noDataText"
			v-bind="$attrs"
			@focus="preloadItems"
		/>
    </div>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
	inheritAttrs: false,
	props: {
		value: {},
		label: {},
		placeholder: {},
		returnObject: { type: Boolean, default: false },
		filters: {},
		rules: {},
		division: {},
		addSelf: {},
		agentAttrs: { type: [Array, String] },
		preloadAgents: { type: Boolean, default: false },
	},
    data () {
        return {
            isLoading: false,
            search_agents: null,
            agent: this.value,
            items: [],
            dont_search: false,
			dontSearchId: null,
			itemValue: 'AgentCode',
        }
    },
    mounted () {
        if (this.value !== null) {
            this.findAgent(this.value)
        }
    },
	computed: {
		noDataText () {
			return this.isLoading
				? 'Data is loading...'
				: 'No data available'
		},
	},
    methods: {
		preloadItems () {
			if (!this.preloadAgents) { return }
			if (this.items.length) { return }
			this.agentDownlineSearch(null, true)
		},
        updateValue (v, emit) {
            this.setDontSearch()
            if (emit === true) {
				const value = v
					? this.returnObject ? v : v[this.itemValue]
					: v
                this.$emit('input', value)
            } else {
				const agentCode = (v && typeof v === 'object') ? v[this.itemValue] : v
				const mAgentCode = this.agent ? this.agent[this.itemValue] : this.agent
				if (mAgentCode !== agentCode) {
					this.agent = this.items.find((item) => item[this.itemValue] === agentCode)
				}
			}
        },
        getItemTextLabel (item) {
            return (item.AgentName + " - " + item.LeadershipLevel + ' (' + item.AgentCode + ')')
        },
        findAgent (agent) {
			const agentcode = (agent && typeof agent === 'object') ? agent[this.itemValue] : agent
            this.isLoading = true
            // Lazily load input items
            QuilityAPI.getAgentStateless(agentcode, this.agentAttrs)
				.then((json) => {
					this.setDontSearch()
					this.agent = json
					// this.$set(this, 'items', [json])
					this.isLoading = false
				}).catch(() => {
					this.updateValue(agent, true)
					this.agent = agent
					this.isLoading = false
				})
        },
        // if we just set the agent code for the v-model we don't want to search again for the same agent.
        setDontSearch () {
			clearTimeout(this.dontSearchId)
            this.dont_search = true
            this.dontSearchId = setTimeout(() => {
                this.dont_search = false
            }, 1000)
        },
		agentDownlineSearch: debounce(function (search, isPreload) {
            // if we just set the agent code for the v-model we don't want to search again for the same agent.
            if (this.dont_search) { return }

            if (!isPreload && (!search || search.length < 3)) {
                this.isLoading = false
                return
            }

            // Items have already been loaded
            //if (this.items.length > 0) return

            // Items have already been requested
            //if (this.isLoading) return

            // Lazily load input items
            this.isLoading = true
            const options = {
                search,
                itemsPerPage: isPreload ? 30 : 100,
                page: 1,
                division: this.division,
                ...this.filters,
            }
            QuilityAPI.getAgentDownlineSearch(null, options, null, true, (json) => {
				let data = json.data
				if (isPreload && data.length > 30) {
					data = data.slice(0, 30)
				}
				if (isPreload) {
					data = [...data].sort((a, b) => a.AgentName.localeCompare(b.AgentName))
				}
                if (this.addSelf) {
					data = [
						...data,
						{
							AgentName: this.user.Agent.AgentName,
							LeadershipLevel: this.user.Agent.LeadershipLevel,
							AgentCode: this.user.Agent.AgentCode,
						},
					]
                }
                this.$set(this, 'items', data)
                this.isLoading = false
            })
        }, 250),
    },
    watch: {
        agent (v) {
			if (v && !this.dont_search) {
				this.dont_search = true
				this.findAgent(v)
			} else {
				this.updateValue(v, true)
			}
        },
        value (v) {
			if (v && !this.dont_search) {
				this.dont_search = true
				this.findAgent(v)
			} else {
				this.updateValue(v, false)
			}
        },
        search_agents (val) {
			this.agentDownlineSearch(val)
		},
    }
}
</script>
