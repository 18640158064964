<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <QDynamicSubmenu
        base-url="/leads"
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        :sticky="false"
        :has-demo="hasDemo"
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QMarketingSubmenu",
    props: {
        hasDemo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "Marketing",
            titlelink: [],
            buttons: [
                {
                    label: "Video Resources",
                    to: "/page/agent-marketing-video-resources",
                    icon: "fas fa-arrow-alt-circle-right",
                }, {
                    label: "Branding Assets",
                    to: "/page/marketing",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Product Assets",
                    to: "/page/product-concepts",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Marketing Request Form",
                    to: "/page/agent-marketing-request-forms ",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
