<!-- this component is used in both My Leads and Available Leads -->
<template>
    <QDynamicSubmenu
        base-url="/leads"
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        :sticky="false"
        :has-demo="hasDemo"
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
import QDynamicSubmenu from "@/components/navigation/Submenus/base/QDynamicSubmenu.vue";

const buttons = [
    {
        label: "HQ How-to",
        to: "/page/hq-how-to",
        icon: "fas fa-arrow-alt-circle-right",
    },
    {
        label: "Switchboard",
        to: "/page/switchboard-sites",
        icon: "fas fa-arrow-alt-circle-right",
    },
    {
        label: "Power Dialer",
        to: "/page/power-dialer",
        icon: "fas fa-arrow-alt-circle-right",
    },
    {
        label: "Virtual Assistant",
        to: "/page/virtual-assistant",
        icon: "fas fa-arrow-alt-circle-right",
    },
    {
        label: "Virtual Mentor",
        to: "/page/virtual-mentor",
        icon: "fas fa-arrow-alt-circle-right",
    },
];

export default {
    props: {
        hasDemo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "Quility Tech",
            titlelink: [],
            buttons,
        };
    },
    components: {
        QDynamicSubmenu,
    },
};
</script>

<style></style>
