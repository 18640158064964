<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :submenu="submenu"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QAgencyBuildingSubmenu",
    data() {
        return {
            title: "Agency Building",
        }
    },
    mounted() {
        this.maybeAutoStartDemo('DemoAgency')
    },
    computed: {
        submenu() {
            if (this.hasRole(['Staff', 'ContentEditor', 'Exec', 'SuperAdmin'])) {
                return [{
                    label: "Help",
                    to: "/support",
                }, {
                    label: "Weekly Onboarding Report",
                    to: "/admin/reports/onboarding_report",
                }, {
                    label: "Start Demo",
                    fun() {
                        this.startDemo();
                    },
                }]
            } else {
                return [{
                    label: "Help",
                    to: "/support"
                }, {
                    label: "Start Demo",
                    fun() {
                        this.startDemo();
                    },
                }]
            }
        },
        buttons() {
            if (this.hasRole(['SalesRep', 'UnlicensedAgent'])) {
                if (this.hasDivision('Corporate')) {
                    return [{
                        label: "Builders Corner",
                        to: "/page/corp-builders-corner",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    }]
                }

                return [{
                    label: "Builders Corner",
                    to: "/page/builders-corner-new",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                }]
            }

            if (this.hasPermission(['manage:Agent'])) {
                return [{
                        label: "Agent Directory",
                        to: "/agency-building/directory",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-agent-directory"
                    },
                    {
                        label: "New Agent",
                        to: "/agents/new_agent",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-new-agent"
                    },
                    {
                        label: "Recruiting Stats",
                        to: "/agency-building/recruiting_stats",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-recruiting"
                    },
                    {
                        label: "Agency Structure",
                        to: "/agency-building/structure",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-structure"
                    },
                    {
                        label: "Builders Corner",
                        to: "/page/builders-corner-new",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    },
                ]
            }

            const buttons = [
                {
                    label: "Agent Directory",
                    to: "/agency-building/directory",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-owner-agent-directory"
                },
                {
                    label: "Agency Structure",
                    to: "/agency-building/structure",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-owner-structure"
                }
            ]

            if (this.hasDivision('Corporate')) {
                buttons.push({
                    label: "Builders Corner",
                    to: "/page/corp-builders-corner",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                })
            } else {
                buttons.push({
                    label: "Builders Corner",
                    to: "/page/builders-corner-new",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                })
                buttons.push({
                    label: "Recruiting Stats",
                    to: "/agency-building/recruiting_stats",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-owner-recruiting"
                })
            }

            return buttons;
        }
    },
    methods: {
        startDemo() {
            this.$tours['DemoAgency'].start()
        }
    },
    components: {
        QDynamicSubmenu,
    },
}

</script>

<style></style>
