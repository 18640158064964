const AUDIENCE_TYPE = {
    AGENT: 'Agent',
    CUSTOMER: 'Customer'
}

const possibleAudience = [
	`q:audience:${AUDIENCE_TYPE.AGENT}`,
	`q:audience:${AUDIENCE_TYPE.CUSTOMER}`,
]

const fromBackend = (data) => {
	if (data.CategoryName !== 'Marketing Resource Library') { return data }

    if (!data.DocumentTags) { 
        data.DocumentTags = []
        return data
    }

    if (typeof data.DocumentTags === 'string') {
		try {
			data.DocumentTags = JSON.parse(data.DocumentTags)
		} catch (e) {
			data.DocumentTags = []
		}
	}

	if (!Array.isArray(data.DocumentTags)) {
        data.DocumentTags = []
    }

	data.Audience = AUDIENCE_TYPE.AGENT

	possibleAudience.forEach((audience) => {
        if (data.DocumentTags.includes(audience)) {
            data.DocumentTags = data.DocumentTags.filter((tag) => tag.trim() !== audience)
            data.Audience = audience.replace('q:audience:', '')
        }
    })

    return data
}

const toBackend = (data) => {
	if (data.CategoryName !== 'Marketing Resource Library') { return data }

    if (!data.DocumentTags) { data.DocumentTags = [] }

    if (data.Audience == AUDIENCE_TYPE.AGENT) {
		data.DocumentTags = [...data.DocumentTags, `q:audience:${AUDIENCE_TYPE.AGENT}`]
    } else if (data.Audience == AUDIENCE_TYPE.CUSTOMER) {
		data.DocumentTags = [...data.DocumentTags, `q:audience:${AUDIENCE_TYPE.CUSTOMER}`]
    }

    data.DocumentTags = JSON.stringify(data.DocumentTags.map((tag) => tag.trim()).filter((tag) => tag !== ''))

    return data
}

export default {
    getDivisionDocumentList(division, category) {
        if (typeof division == 'undefined' || division == null || division == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = '/api/private/agent_document';
        this.cancelQRequest("divisionDocumentsQ")
        return this.getRequest(url, { 'Division': division, 'CategoryName': category }, 'divisionDocumentsQ').then(function(json) {
			json.data = json.data.map(fromBackend)
            return json;
        })
    },

    deleteDivisionDocument(id) {
        return this.deleteRequest('/api/private/agent_document' + id).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Document not found"
                } else {
                    msg = err.response.message
                }
                return (msg)
            })
    },

    updateDivisionDocument(doc) {
        return this.putRequest('/api/private/agent_document/' + doc.ID, doc).then(
            function(json) {
				json.file = fromBackend(json.file)
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Document not found"
                } else {
                    msg = err.response.message
                }
                return (msg)
            })
    },

	async uploadDocument (Division, CategoryName, document, id) {
		const data = { Division, CategoryName, document }

        const formData = new FormData()

        for (const name in data) {
			formData.append(name, data[name])
        }

        const url = id
			? `/api/private/agent_document/${id}`
			: '/api/private/agent_document'

        const token = await this.config()

        return fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((r) => r.json())
	},

    async uploadDivisionDocument (doc, file, id = doc.ID) {
        const data = toBackend({
            DocTitle: doc.DocTitle,
            DocDesc: doc.DocDesc,
            Division: doc.Division,
            CategoryName: doc.CategoryName,
            SecureDocumentInd: doc.SecureDocumentInd,
            SearchableInd: doc.SearchableInd,
            ...doc,
        })

		let document = {}

		if (file) {
			try {
				document = await this.uploadDocument(doc.Division, doc.CategoryName, file, id)
			} catch (err) {
				return (err)
			}
		}

		return this.updateDivisionDocument({ ...document.file, ...data })
    },

	getMarketingResourceLibrary (params = {}) {
        const url = '/api/private/marketing_resource_library'
        this.cancelQRequest('MarketingResourceLibraryQ')
        return this.getRequest(url, params, 'MarketingResourceLibraryQ')
			.then((json) => {
				json.data = json.data.map(fromBackend)
				return json
			})
			.catch((err) => {
				return {
					error: true,
					msg: err.message,
				}
			})
    },

	getMarketingResourceLibrarySubjects (params = {}) {
        const url = '/api/private/agent_document_subjects'
        this.cancelQRequest('MarketingResourceLibrarySubjectsQ')
        return this.getRequest(url, params, 'MarketingResourceLibrarySubjectsQ')
			.then((json) => json)
			.catch((err) => {
				return {
					error: true,
					msg: err.message,
				}
			})
    },

    async getMarketingResourcesGroupsList () {
		const response = await this.getMarketingResourceLibrarySubjects()
		if (response.error) { return response }
		return Object.keys(response.data)
    },

	async getMarketingResourcesTagsList () {
		const response = await this.getMarketingResourceLibrarySubjects()
		if (response.error) { return response }
		const groups = Object.keys(response.data)
		if (!groups.length) { return [] }
		const items = groups.map(group => response.data[group]).flat()
		const uniqueItems = [...new Set(items)].sort()
		const withoutDocumentTagsIndex = uniqueItems.indexOf('-empty-')
		if (withoutDocumentTagsIndex !== -1) {
			uniqueItems.splice(withoutDocumentTagsIndex, 1)
		}
		return uniqueItems
	},
}
